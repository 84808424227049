import React, { useState, useEffect } from 'react';
import DOMpurify from 'dompurify';
import '../../styles/form.css';

import {
  createTicket,
  USER_TYPE_TO_INBENTA_ID,
  isUserExist,
} from '../../../services/api';

const Fusion = ({ userInfoSSFF }) => {
  const [dni, setDni] = useState('');
  const [numeroFija, setNumeroFija] = useState('');
  const [domicilioFija, setDomicilioFija] = useState('');
  const [telefonoContacto, setTelefonoContacto] = useState('');
  const [horarioContacto, setHorarioContacto] = useState('');
  const [opcion, setOpcion] = useState('max');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showDniRequired, setShowDniRequired] = useState(false);
  const [showNumeroFijaRequired, setShowNumeroFijaRequired] = useState(false);
  const [showDomicilioFijaRequired, setShowDomicilioFijaRequired] =
    useState(false);
  const [showTelefonoContactoRequired, setShowTelefonoContactoRequired] =
    useState(false);
  const [showHorarioContactoRequired, setShowHorarioContactoRequired] =
    useState(false);
  const [showOpcionRequired, setShowOpcionRequired] = useState(false);
  //Estado para abrir el popup de feedback al usuario
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [messageSendingFailed, setMessageSendingFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Forzar el scroll a la parte superior cuando el componente se monta
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const areFieldsValid =
      dni.trim() !== '' &&
      numeroFija.trim() !== '' &&
      domicilioFija.trim() !== '' &&
      telefonoContacto.trim() !== '' &&
      horarioContacto.trim() !== '' &&
      opcion.trim() !== '';
    setIsButtonEnabled(areFieldsValid);
  }, [
    dni,
    numeroFija,
    domicilioFija,
    telefonoContacto,
    horarioContacto,
    opcion,
  ]);

  const handleDniChange = (e) => {
    setDni(e.target.value);
    setShowDniRequired(false);
  };

  const handleNumeroFijaChange = (e) => {
    setNumeroFija(e.target.value);
    setShowNumeroFijaRequired(false);
  };

  const handleDomicilioFijaChange = (e) => {
    setDomicilioFija(e.target.value);
    setShowDomicilioFijaRequired(false);
  };

  const handleTelefonoContactoChange = (e) => {
    setTelefonoContacto(e.target.value);
    setShowTelefonoContactoRequired(false);
  };

  const handleHorarioContactoChange = (e) => {
    setHorarioContacto(e.target.value);
    setShowHorarioContactoRequired(false);
  };

  const handleOpcionChange = (e) => {
    setOpcion(e.target.value);
    setShowOpcionRequired(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const areFieldsValid =
      dni.trim() !== '' &&
      numeroFija.trim() !== '' &&
      domicilioFija.trim() !== '' &&
      telefonoContacto.trim() !== '' &&
      horarioContacto.trim() !== '' &&
      opcion !== '';
    if (dni === '') {
      setShowDniRequired(true);
    }
    if (numeroFija === '') {
      setShowNumeroFijaRequired(true);
    }
    if (domicilioFija === '') {
      setShowDomicilioFijaRequired(true);
    }
    if (telefonoContacto === '') {
      setShowTelefonoContactoRequired(true);
    }
    if (horarioContacto === '') {
      setShowHorarioContactoRequired(true);
    }
    if (opcion === '') {
      setShowOpcionRequired(true);
    } else if (areFieldsValid) {
      const dataUserInbenta = await isUserExist(userInfoSSFF.username);

      try {
        setIsSubmitting(true);
        // Sanitizar los valores de entrada del formulario
        const sanitizedDni = DOMpurify.sanitize(dni);
        const sanitizedNumeroFija = DOMpurify.sanitize(numeroFija);
        const sanitizedDomicilioFija = DOMpurify.sanitize(domicilioFija);
        const sanitizedTelefonoContacto = DOMpurify.sanitize(telefonoContacto);
        const sanitizedHorarioContacto = DOMpurify.sanitize(horarioContacto);
        const sanitizedOpcion = DOMpurify.sanitize(opcion);

        const message = `
        <div style={{ backgroundColor: 'transparent' }}>
          <div><br /></div>
          <div>
          1. EMAIL CORPORATIVO
            <br />
            <b>${userInfoSSFF.username}</b>
            </div>
            <div><br /></div>
          <div>
          2. NOMBRE Y APELLIDOS
           <br />
           <b>${dataUserInbenta.data[0].name}</b>
           </div>
           <div><br /></div>
          <div>
            3. DNI
            <br />
            <b>${sanitizedDni}</b>
            </div>
          <div><br /></div>
          <div>
            4. EMPRESA
            <br />
            <b>${
              USER_TYPE_TO_INBENTA_ID[userInfoSSFF.custom03].hcCompanyName
            }</b>
        </div>
          <div><br /></div>
          <div>
            5. NÚMERO DE LÍNEA FIJA
            <br />
            <b>${sanitizedNumeroFija}</b>
            </div>
            <div><br /></div>
            <div>
            6. DOMICILIO EN EL QUE SE UBICA LA LÍNEA
            <br />
            <b>${sanitizedDomicilioFija}</b>
            </div>
          <div><br /></div>
          <div>
          7. TELÉFONO DE CONTACTO
          <br />
          <b>${sanitizedTelefonoContacto}</b>
          </div>
          <div><br /></div>
          <div>
          8. HORARIO PREFERIDO DE CONTACTO
          <br />
          <b>${sanitizedHorarioContacto}</b>
          </div>
          <div><br /></div>
          <div>
            9. ELEGIR PAQUETE FUSIÓN MOVISTAR+
            <br />
            <b>${sanitizedOpcion}</b>
          </div>
          <div><br /></div>
        </div>
      `;

        await createTicket(
          'NUEVA Solicitud OFERTA FUSION empleados',
          message,
          userInfoSSFF.custom03,
          1,
          [],
          dataUserInbenta.data[0].id
        );

        setDni('');
        setNumeroFija('');
        setDomicilioFija('');
        setTelefonoContacto('');
        setHorarioContacto('');
        setOpcion('');
        setFeedbackPopup(true);
      } catch (error) {
        console.error('Error al enviar el mensaje:', error);
        setMessageSendingFailed(true);
        setMessageSendingFailed(false);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCloseFeedbackPopup = () => {
    setFeedbackPopup(false);
    setMessageSendingFailed(false);
  };

  return (
    <>
      <section className="formArticle">
        <h1 className="formArticle__title">
          Solicitud oferta Movistar para empleados
        </h1>
        <p className="formArticle__text">
          Recuerda que tienes la opción de solicitar un préstamo a la compañía,
          atendiendo siempre a los requisitos asociados a tu jurídica.
        </p>
        <form
          target="_blank"
          className="formArticle__form"
          action="#"
          onSubmit={handleSubmit}
        >
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="dni">
              DNI
              {showDniRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={dni}
              onChange={handleDniChange}
            />
          </div>
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="numeroFija">
              Número de línea fija
              {showNumeroFijaRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={numeroFija}
              onChange={handleNumeroFijaChange}
            />
          </div>
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="domicilioFija">
              Domicilio en el que se ubica la línea
              {showDomicilioFijaRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={domicilioFija}
              onChange={handleDomicilioFijaChange}
            />
          </div>
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="telefonoContacto">
              Teléfono de contacto
              {showTelefonoContactoRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={telefonoContacto}
              onChange={handleTelefonoContactoChange}
            />
          </div>
          <div className="formArticleSection">
            <label className="formArticleLabel" htmlFor="horarioContacto">
              Horario preferido de contacto
              {showHorarioContactoRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <input
              type="text"
              placeholder="Escriba su respuesta"
              className="formArticleInputText"
              value={horarioContacto}
              onChange={handleHorarioContactoChange}
            />
          </div>
          <div className="formArticleSection">
            <label htmlFor="formArticle__option" className="formArticleLabel">
              Paquete de Movistar contratado
              {showOpcionRequired && (
                <p className="formArticleLabel__required">
                  Este es un campo requerido
                </p>
              )}
            </label>
            <div className="formArticle_radio">
              <input
                type="radio"
                name="formArticle__option"
                value="Movistar Max"
                defaultChecked
                onChange={handleOpcionChange}
              />
              <label htmlFor="Movistar Max"> Movistar Max</label>
              <br></br>
              <input
                type="radio"
                name="formArticle__option"
                value="Movistar Ilimitado"
                checked={opcion === 'Movistar Ilimitado'}
                onChange={handleOpcionChange}
              />
              <label htmlFor="Movistar Ilimitado"> Movistar Ilimitado</label>
              <br></br>
              <input
                type="radio"
                name="formArticle__option"
                value="Movistar Ilimitado x2"
                checked={opcion === 'Movistar Ilimitado x2'}
                onChange={handleOpcionChange}
              />
              <label htmlFor="Movistar Ilimitado x2">
                {' '}
                Movistar Ilimitado x2
              </label>
              <br></br>
              <input
                type="radio"
                name="formArticle__option"
                value="Movistar Ilimitado x4"
                checked={opcion === 'Movistar Ilimitado x4'}
                onChange={handleOpcionChange}
              />
              <label htmlFor="Movistar Ilimitado x4">
                {' '}
                Movistar Ilimitado x4
              </label>
            </div>
          </div>
          <div className="formArticleButton__section">
            <button
              className={`formArticleButton ${
                isButtonEnabled ? 'enabled' : 'disabled'
              }`}
              type="submit"
            >
              Enviar
            </button>
            {isSubmitting && (
              // SPINNER LOADING
              <div class="loader"></div>
            )}
          </div>
        </form>
        <div className="contactTextContainer">
          <p className="formArticle__contact">
            Para cualquier duda, contacta al buzón{' '}
            <a href="mailto:preguntanos@personas.telefonica.com">
              preguntanos@personas.telefonica.com
            </a>
          </p>
        </div>
        {feedbackPopup && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content">
              <div className="dislikePopup__sectionTitle">
                <h1 className="dislikePopup__sectionTitle--title spacing">
                  Solicitud enviada correctamente
                </h1>
              </div>
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  Nos pondremos en contacto contigo a la mayor brevedad posible
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
        {messageSendingFailed && (
          <div className="dislikePopup-overlay">
            <div className="dislikePopup-content failurePopup">
              <div className="dislikePopup__sectionText">
                <p className="timeOutPopup__sectionText--text">
                  No se ha podido enviar su mensaje. <br /> Inténtelo de nuevo.
                </p>
              </div>
              <div className="contactMeSectionButton">
                <button
                  className="contactMePopup--button failureButton"
                  onClick={handleCloseFeedbackPopup}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Fusion;
